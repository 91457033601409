import React from 'react';
import { graphql } from 'gatsby';
// import loadable from '@loadable/component';
import { HelmetDatoCms } from '@rasahq/gatsby-source-datocms';

import pageModules from '../modules';

// const LoadableModularPage = (props) => {
//   console.table(props);
//   const LLMP = loadable(async () => {
//     const {
//       data: { datoCmsPage: page },
//     } = props;
//     const Header = pageModules[page.header.moduleComponent.filePath];
//     await Header.load();
//     return ModularPage;
//   });
//   return <LLMP {...props} />;
// };

// export default LoadableModularPage;

export default function ModularPage({ data: { datoCmsPage: page } }) {
  const Header = pageModules[page.header.moduleComponent.filePath];
  return (
    <>
      <HelmetDatoCms seo={page.seoMetaTags} />
      {Header && <Header {...page.header} />}
      {page.drawers.map((section, i) => {
        const { filePath } = section.moduleComponent;
        const Section = pageModules[filePath];
        return Section && <Section key={`${i}-${filePath}`} {...section} />;
      })}
    </>
  );
}

export const query = graphql`
  query ModularPage($id: String) {
    datoCmsPage(id: { eq: $id }) {
      ...GatsbyDatoCmsPageData
    }
  }
`;
